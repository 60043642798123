import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Hem: "/",
  "Integritetspolicy": "se/integritetspolicy"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/privacy-policy"
);

const PrivacyPolicy = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Integritetspolicy"
        description="Detaljer om min dataskyddspolicy för att förklara hur mina tjänster fungerar och hur skyddet av dina personuppgifter garanteras."
        lang="se"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Dataskydd</H>
        <p>Integritetspolicy</p>
        <p>
          Nedan vill jag förklara vilken data jag samlar in, bearbetar och använder, när och för vilket syfte. Syftet är att
          förklara hur mina tjänster fungerar och hur skyddet av dina personuppgifter garanteras i detta avseende.
        </p>
        <p>Jag samlar endast in, bearbetar och använder personuppgifter där du har gett ditt samtycke eller där lagstiftning tillåter det.</p>
        <p>
          Denna integritetspolicy kan laddas ner, sparas och skrivas ut när som helst via URL
          https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Personuppgiftsansvarigs namn och adress</H>
        <p>Personuppgiftsansvarig i den mening som avses i dataskyddslagstiftningen är:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Köpenhamn, Danmark</p>
        <p>Du kan kontakta mig när som helst via e-post på legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Namn och adress till dataskyddsombudet</H>
        <p>Dataskyddsombudets kontaktuppgifter:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Köpenhamn, Danmark</p>
        <p>Du kan kontakta mig när som helst via e-post på legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Allmänna punkter om dataskydd</H>
        <p>Omfattning av personuppgiftsbehandling</p>
        <p>
          Jag bearbetar endast användares personuppgifter där det krävs för att tillhandahålla en operativ webbplats samt innehåll
          och tjänster. Som regel bearbetas användares personuppgifter endast efter att användaren har gett sitt samtycke. Ett undantag
          gäller i sådana fall där samtycke inte kan erhållas i förväg av faktiska skäl, och bearbetning av
          uppgifterna är tillåten enligt lagstadgade bestämmelser.
        </p>
        <H as="h2">Rättslig grund för behandling av personuppgifter</H>
        <p>
          Artikel 6 (1, a) i EU:s allmänna dataskyddsförordning (GDPR) fungerar som rättslig grund där jag erhåller en
          registrerads samtycke för att bearbeta personuppgifter.
        </p>
        <p>
          Artikel 6 (1, b) GDPR fungerar som rättslig grund där behandling av personuppgifter krävs för
          att fullgöra ett avtal där den registrerade är part i avtalet. Detta gäller även för behandling
          som krävs för att genomföra åtgärder före avtalsslut.
        </p>
        <p>
          Artikel 6 (1, c) GDPR fungerar som rättslig grund där behandling av personuppgifter krävs för
          att fullgöra en rättslig skyldighet som mitt företag är föremål för.
        </p>
        <p>
          Där behandlingen krävs för att skydda ett legitimt intresse för mitt företag eller en tredje part, och
          den registrerades intressen och grundläggande rättigheter och friheter inte väger tyngre än det förstnämnda
          intresset, fungerar artikel 6 (1, f) GDPR som rättslig grund för behandling.
        </p>

        <H as="h2">Radering av data och lagringens varaktighet</H>
        <p>
          Den registrerades personuppgifter raderas eller blockeras så snart syftet med lagringen upphör.
          Lagring kan också ske om det är avsett av europeiska eller nationella lagstiftare i förordningar, lagar eller andra
          krav enligt EU-lagstiftning som den personuppgiftsansvarige är föremål för. Data raderas också eller raderas om en lagringsperiod
          föreskriven av den nämnda lagstiftningen har gått ut, om det inte finns ett krav på fortsatt lagring
          av uppgifterna för att ingå eller fullgöra ett avtal.
        </p>
        <p>Typer av behandlade data:</p>
        <p>– Kontaktuppgifter (t.ex. e-post, namn).</p>
        <p>– Användningsdata (t.ex. besökta sidor, intresse för innehåll, åtkomsttider).</p>
        <p>– Meta/kommunikationsdata (t.ex. enhetsinformation, IP-adresser).</p>
        <H as="h2">Kategorier av registrerade</H>
        <p>Besökare och användare av det online-erbjudandet, alla sammanfattade som "användare" härnedan.</p>
        <H as="h2">Syfte med behandlingen</H>
        <p>– Tillhandahållande av online-erbjudandet, dess funktioner och innehåll.</p>
        <p>– Svara på kontaktförfrågningar och kommunikation med användare.</p>
        <p>– Säkerhetsåtgärder.</p>
        <p>– Publikmätning/marknadsföring</p>
        <H as="h3">Använda definitioner</H>
        <p>
          "Personuppgifter" betyder all information som rör en identifierad eller identifierbar fysisk person ("registrerad");
          en identifierbar fysisk person är en som kan identifieras, direkt eller indirekt, särskilt med hänvisning till en identifierare som ett namn, ett identifikationsnummer, lokaliseringsdata, en onlineidentifierare (t.ex. cookie) eller till en eller flera faktorer som är specifika för den fysiska, fysiologiska, genetiska, mentala, ekonomiska, kulturella eller sociala identiteten för den fysiska personen.
        </p>
        <p>
          "Behandling" betyder varje åtgärd eller uppsättning av åtgärder som utförs på personuppgifter eller på uppsättningar av personuppgifter, vare sig det sker automatiserat eller inte. Begreppet är långtgående och täcker praktiskt taget all hantering av data.
        </p>
        <p>
          "Personuppgiftsansvarig" avser den fysiska eller juridiska person, offentliga myndighet, byrå eller annat organ som, ensam eller tillsammans med andra, bestämmer ändamålen och medlen för behandlingen av personuppgifter.
        </p>
        <H as="h2">Relevanta rättsliga grunder</H>
        <p>
          I enlighet med artikel 13 GDPR informerar jag dig om de rättsliga grunderna för vår databehandling. Följande gäller där den rättsliga grunden inte anges i dataskyddspolicyn: Den rättsliga grunden för att erhålla samtycke är artikel 6 (1, a) och artikel 7 GDPR, den rättsliga grunden för behandling för att utföra våra tjänster och genomföra avtalsåtgärder, samt att svara på förfrågningar är artikel 6 (1, b) GDPR, den rättsliga grunden för behandling för att uppfylla våra rättsliga skyldigheter är artikel 6 (1, c) GDPR, och den rättsliga grunden för behandling för att skydda våra legitima intressen är artikel 6 (1, f) GDPR.
        </p>
        <H as="h2">Samarbete med personuppgiftsbiträden och tredje parter</H>
        <p>
          När jag vid behandling avslöjar data till andra parter och företag (personuppgiftsbiträden eller tredje parter), eller ger direkt eller annat tillgång till uppgifterna, sker detta endast baserat på lagstadgad tillåtelse (t.ex. om överföring av data till tredje parter, som en betalningstjänstleverantör, krävs för att utföra ett avtal i enlighet med artikel 6 (1, b) GDPR, du har gett samtycke, en rättslig skyldighet finns, eller baserat på våra legitima intressen (t.ex. vid användning av agenter, webbhotell osv.).
        </p>
        <p>
          När jag anlitar tredje parter för att behandla data baserat på ett så kallat "biträdesavtal", sker detta baserat på artikel 28 GDPR.
        </p>
        <H as="h2">Överföring till tredje länder</H>
        <p>
          När jag behandlar data i ett tredje land (dvs. utanför Europeiska unionen (EU) eller Europeiska ekonomiska samarbetsområdet (EES)), eller detta sker när det används som en del av tredje parts tjänster eller avslöjandet eller, i förekommande fall, överföring av data till tredje parter, sker detta endast om det krävs för att utföra våra (före-)avtalsmässiga skyldigheter, baserat på ditt samtycke, på grund av en rättslig skyldighet, eller baserat på våra legitima intressen. Med förbehåll för lagstadgad eller avtalsenlig tillåtelse, behandlar jag endast, eller arrangerar att data behandlas, i ett tredje land där de specifika kraven i artiklarna 44 ff GDPR finns på plats. Med andra ord sker behandlingen, till exempel, baserat på särskilda garantier som den officiellt erkända bestämningen av dataskyddsnivån som motsvarar EU (t.ex. för USA genom "Privacy Shield") eller efterlevnad av officiellt erkända specifika skyldigheter (så kallade "standardavtalsklausuler").
        </p>
        <H as="h2">Rättigheter för registrerade</H>
        <p>
          Du har rätt att kräva bekräftelse på huruvida uppgifterna i fråga behandlas och för information om dessa uppgifter, samt ytterligare information och en kopia av uppgifterna i enlighet med artikel 15 GDPR.
        </p>
        <p>
          I enlighet med artikel 16 GDPR har du rätt att kräva att uppgifter som rör dig kompletteras, eller att felaktiga uppgifter om dig rättas.
        </p>
        <p>
          I enlighet med artikel 17 GDPR har du rätt att kräva att uppgifter om dig raderas utan onödigt dröjsmål eller, alternativt i enlighet med artikel 18 GDPR, begränsad behandling av uppgifterna.
        </p>
        <p>
          Du har rätt att kräva att få tillgång till de uppgifter som du har tillhandahållit mig i enlighet med artikel 20 GDPR, och att begära att dessa överförs till en annan personuppgiftsansvarig.
        </p>
        <p>
          Du har också rätt i enlighet med artikel 77 GDPR att lämna in ett klagomål till den relevanta tillsynsmyndigheten.
        </p>
        <H as="h2">Återkallelse</H>
        <p>Du har rätt att återkalla samtycke som beviljats i enlighet med artikel 7 (3) GDPR med framtida verkan.</p>
        <H as="h2">Rätt att invända</H>
        <p>
          I enlighet med artikel 21 GDPR kan du invända mot framtida behandling av uppgifter som rör dig. Särskilt kan invändningen
          riktas mot behandling för direktreklam.
        </p>
        <H as="h2">Cookies och rätt att invända mot direktreklam</H>
        <p>
          Cookies avser små filer som lagras på användarnas datorer. Olika detaljer kan lagras inom cookies.
          En cookie används främst för att lagra detaljer om en användare (eller, i förekommande fall, enheten på vilken cookien är
          lagrad) under eller efter hans/hennes besök inom ett online-erbjudande. Cookies som raderas efter att en användare
          lämnar ett online-erbjudande och stänger sin webbläsare kallas tillfälliga cookies eller, i förekommande fall,
          sessionscookies. Till exempel kan en inloggningsstatus lagras i en sådan cookie. Cookies som kvarstår även efter att webbläsaren har
          stängts kallas permanenta eller beständiga cookies. Till exempel möjliggör detta att inloggningsstatusen lagras om användaren besöker det
          efter flera dagar. Användarnas intressen för mätning av publik eller marknadsföringsändamål kan också lagras i en sådan cookie. Cookies
          som erbjuds av andra leverantörer än den personuppgiftsansvarige som driver det online-erbjudandet kallas tredjepartscookies (annars förstepartscookies
          om endast dess cookies finns).
        </p>
        <p>Jag kan använda tillfälliga och permanenta cookies och klargöra detta under vår dataskyddspolicy.</p>
        <p>
          Om användare inte vill att cookies ska lagras på deras dator, uppmanas de att inaktivera motsvarande alternativ i systeminställningarna.
          Lagrade cookies kan raderas i webbläsarens systeminställningar. Att exkludera cookies kan leda till begränsad funktionalitet i detta online-erbjudande.
        </p>
        <p>
          En allmän invändning mot användningen av cookies som placeras för online-marknadsföringsändamål kan deklareras med en rad
          tjänster, framför allt i händelse av spårning, via den amerikanska webbplatsen http://www.aboutads.info/choices/ eller den europeiska webbplatsen http://www.youronlinechoices.com/. Dessutom kan lagring
          av cookies uppnås genom att inaktivera dem i webbläsarens inställningar. Observera att inte alla
          funktioner i detta online-erbjudande då kan användas.
        </p>
        <p>
          När du besöker vår webbplats informeras användarna om användningen av cookies för analysändamål genom en informationsbanner som hänvisar till dataskyddspolicyn. I detta avseende finns det också en hänvisning till hur
          lagring av cookies kan förhindras i webbläsarinställningarna. &nbsp;Detta så kallade cookie-spårningsfilter
          hjälper användaren att spara vilka cookies som ska sättas. Hans/hennes egen cookie lagras för detta ändamål. Om
          du raderar dina cookies bör du inte radera denna cookie, eftersom cookie-spårningsfiltret annars inte kan
          känna igen dina preferenser.
        </p>
        <H as="h2">Radering av data</H>
        <p>
          Uppgifter som behandlas av mig raderas i enlighet med artiklarna 17 och 18 GDPR, eller begränsas i sin behandling.
          Om inget annat uttryckligen anges som en del av dataskyddspolicyn, raderas uppgifter som lagras hos mig så snart de
          inte längre krävs för sitt syfte, och inga lagstadgade lagringsskyldigheter står i vägen för radering. Om
          uppgifter inte raderas eftersom de krävs för andra lagstadgade ändamål som tillåts enligt lag, begränsas deras
          behandling. Med andra ord blockeras uppgifterna och behandlas inte för andra ändamål. Till exempel gäller detta
          uppgifter som behöver behållas enligt kommersiell eller skatterätt.
        </p>
        <H as="h2">Affärsrelaterad behandling</H>
        <p>Jag behandlar också</p>
        <p>– kontraktsdata (t.ex. avtalsobjekt, löptid, kundkategori).</p>
        <p>– betalningsdata (t.ex. bankuppgifter, betalningshistorik)</p>
        <p>
          för mina kunder, potentiella kunder och affärspartners i syfte att tillhandahålla avtalstjänster,
          underhåll och kundvård, marknadsföring, reklam och marknadsundersökningar.
        </p>
        <H as="h2">Hosting</H>
        <p>
          De hostingtjänster jag använder tjänar till att tillhandahålla följande tjänster: Infrastrukturtjänster och plattformstjänster,
          datorkapacitet, lagringsutrymme och databastjänster, samt säkerhetstjänster och tekniska
          underhållstjänster som jag använder för att driva detta online-erbjudande.
        </p>
        <p>
          I samband med detta behandlar jag eller, om tillämpligt, min hosting-leverantör inventeringsdata, kontaktuppgifter, innehållsdata,
          kontraktsdata, användningsdata, meta- och kommunikationsdata för kunder och potentiella kunder samt besökare
          till detta online-erbjudande baserat på våra legitima intressen i att effektivt och säkert tillhandahålla detta online-erbjudande
          i enlighet med artikel 6 (1, f) GDPR i kombination med artikel 28 GDPR (ingående av ett
          biträdesavtal).
        </p>
        <H as="h2">Insamling av åtkomstdata och loggfiler</H>
        <p>
          Baserat på mitt legitima intresse i enlighet med artikel 6 (1, f) GDPR samlar jag eller, om tillämpligt, min hosting-leverantör in data om alla åtkomster till servern där denna tjänst finns (så kallade serverloggfiler). Åtkomstdata inkluderar namnet på den webbplats som besöks, fil, datum och tid för åtkomst, mängden överförd data, rapport om lyckad åtkomst, webbläsartyp tillsammans med version, användarens operativsystem, hänvisnings-URL (tidigare besökt webbplats), IP-adress och begärande leverantör.
        </p>
        <p>
          Tillfällig lagring av IP-adressen av systemet är nödvändig för att möjliggöra leverans av webbplatsen till
          användarens dator. Användarens IP-adress måste förbli lagrad under sessionens varaktighet för att möjliggöra detta.
        </p>
        <p>
          Lagring sker i loggfiler för att säkerställa webbplatsens funktionalitet, främst för att analysera en
          attack (DOS-attack) och missbruk av tillhandahållna verktyg. &nbsp;Data används också för att optimera webbplatsen och
          säkerställa säkerheten för mina IT-system. Data utvärderas inte för marknadsföringsändamål i detta avseende.
        </p>
        <p>
          Uppgifterna raderas så snart de inte längre behövs för att uppnå syftet med insamlingen.
          &nbsp;Detta är vanligtvis fallet efter 30 dagar.
        </p>
        <p>
          Insamlingen av data för att tillhandahålla webbplatsen och lagra data i loggfiler är obligatorisk för
          driften av webbplatsen. Som ett resultat kan användaren inte invända.
        </p>
        <H as="h2">Tillhandahållande av avtalsenliga tjänster</H>
        <p>
          Jag behandlar inventeringsdata (t.ex. namn och adresser samt kontaktuppgifter till kunder) och kontraktsdata (t.ex. tjänster som används, namn på kontaktpersoner, betalningsinformation) för att uppfylla mina
          avtalsenliga skyldigheter och tjänster i enlighet med artikel 6 (1, b) GDPR. Poster markerade som obligatoriska
          i onlineformulär krävs för att ingå avtalet.
        </p>
        <p>
          När du använder mina onlinetjänster lagrar jag IP-adressen och tiden för respektive användaraktivitet.
          Lagring sker baserat på mina legitima intressen, samt för att skydda användaren från missbruk och annan
          obehörig användning. Dessa uppgifter lämnas i princip inte ut till tredje part, om det inte krävs för att följa
          mina krav eller om det finns en lagstadgad skyldighet i detta avseende i enlighet med artikel 6 (1, c) GDPR.
        </p>
        <p>
          Jag behandlar användningsdata (t.ex. besökta sidor av mitt online-erbjudande, intresse för mina produkter) och innehållsdata (t.ex.
          inlägg i kontaktformuläret eller användarprofil) för marknadsföringsändamål i en användarprofil för att visa användarna, till
          exempel, produktinformation baserat på tjänster som tidigare använts av dem.
        </p>
        <p>
          Uppgifterna raderas vid utgången av lagstadgad garanti och jämförbara skyldigheter, och kravet på att behålla
          uppgifterna kontrolleras vart tredje år. Vid lagstadgade arkiveringsskyldigheter sker radering vid utgången av denna period.
          Detaljer i kundkonton kvarstår tills de raderas.
        </p>
        <H as="h2">Administration, redovisning, kontorsorganisation, kontaktadministration</H>
        <p>
          Jag behandlar data som en del av administrativa uppgifter samt organisationen av min verksamhet, redovisning och
          efterlevnad av lagstadgade skyldigheter som arkivering. Som sådan behandlar jag samma data som jag behandlar när
          jag tillhandahåller mina avtalsenliga tjänster. Grunderna för behandling är artikel 6 (1, c) GDPR och artikel 6 (1, f)
          GDPR. Kunder, potentiella kunder, affärspartners och besökare på webbplatsen påverkas av
          behandlingen. Syftet och intresset med behandlingen ligger i administration, redovisning, kontors
          organisation och arkivering av data, dvs. uppgifter som tjänar till att upprätthålla min affärsverksamhet, utföra mina
          uppgifter och tillhandahålla mina tjänster. Radering av data med avseende på avtalsenliga tjänster och kommunikation
          motsvarar detaljerna som anges i dessa behandlingsaktiviteter.
        </p>
        <p>
          Som sådan avslöjar eller överför jag data till Skatteverket, konsulter som skatterådgivare eller revisorer och
          andra avgiftsindrivare och betalningstjänstleverantörer.
        </p>
        <p>
          Dessutom lagrar jag, baserat på mina kommersiella intressen, detaljer om leverantörer, arrangörer och andra
          affärspartners, till exempel för att kunna kontakta dem vid senare tillfälle. I princip delar jag dessa
          data som helhet på permanent basis.
        </p>
        <H as="h2">Affärsanalys och marknadsundersökning</H>
        <p>
          För att kunna driva min verksamhet kommersiellt och identifiera marknadstrender samt kund- och användarönskemål analyserar jag de data som är tillgängliga för mig om affärsprocesser, avtal, förfrågningar etc. I samband med detta behandlar jag inventeringsdata, kommunikationsdata, kontraktsdata, betalningsdata, användningsdata och metadata baserat på artikel 6 (1, f) GDPR, där registrerade inkluderar kunder, potentiella kunder, affärspartners och besökare och användare av det online-erbjudandet.
        </p>
        <p>
          Analys sker för affärsutvärdering, marknadsföring och marknadsundersökning. Detta gör att jag kan överväga profilerna för registrerade användare med detaljer som deras inköpsprocesser. Analysen hjälper mig att öka användarvänligheten och optimera mitt erbjudande och min affärseffektivitet. Analysen tjänar endast mig och avslöjas inte externt, om det inte rör sig om anonymiserad analys med sammanfattade värden.
        </p>
        <p>
          Om denna analys eller dessa profiler är personliga raderas eller anonymiseras de vid avslutning av användarkontot, annars efter två år från att avtalet avslutats. Dessutom görs övergripande kommersiell analys och allmän trendbestämning där det är möjligt.
        </p>
        <H as="h2">Funktion för e-postregistrering</H>
        <p>
          Användare har möjlighet att prenumerera på mitt innehåll med sin e-post. Användarna får de nödvändiga obligatoriska uppgifterna vid registreringen. Uppgifterna som anges vid registreringen används för att dra nytta av erbjudandet. Användare kan få e-postmeddelanden relaterade till erbjudanden eller ändringar i omfattningen av erbjudandet eller tekniska villkor. Om användare har avslutat sitt användarkonto raderas deras uppgifter med avseende på användarkontot, under förutsättning att lagring krävs av kommersiella eller skatterättsliga skäl i enlighet med artikel 6 (1, c) GDPR. Det är upp till användarna att säkra sina data före kontraktets slut vid avbokning. Jag har rätt att oåterkalleligen radera alla användarens data som lagras under kontraktet.
        </p>
        <p>
          När mina registrerings- och inloggningsfunktioner används, samt när användarkontot används, lagrar jag IP-adressen och tiden för respektive användaraktivitet. Lagring sker inte bara baserat på mina legitima intressen, utan också för att skydda användaren mot missbruk och annan obehörig användning. Dessa uppgifter lämnas i princip inte ut till tredje part, om det inte krävs för att följa mina krav eller om det finns en lagstadgad skyldighet i detta avseende i enlighet med artikel 6 (1, c) GDPR.
        </p>
        <H as="h2">Kontakt</H>
        <p>
          När du kontaktar mig (till exempel via kontaktformuläret, e-post, telefon eller via sociala medier) behandlas användarens uppgifter för att hantera kontaktförfrågan och i enlighet med artikel 6 (1, b) GDPR. Användarens uppgifter kan lagras i ett kundrelationshanteringssystem (CRM-system) eller ett jämförbart förfrågningshanteringssystem.
        </p>
        <p>
          Jag raderar förfrågningarna så snart de inte längre behövs. Jag granskar behovet vartannat år, och lagstadgade arkiveringsskyldigheter gäller också.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;
